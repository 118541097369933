import React from 'react'
import './AboutUs.css'
import { translation } from 'assets/translations/translations'

type Props = {
  language: string
}

const AboutUs = ({ language }: Props) => {
  return (
    <div className='about-us-main'>
      <div className='about-us-title'>
        {translation(language, 'aboutUsTitle')}
      </div>
      <div className='about-us-text'>
        {translation(language, 'aboutUsText')}
      </div>
    </div>
  )
}

export default AboutUs
