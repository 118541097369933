import english from './en.json'
import macedonian from './mk.json'

export const translation = (
  language: string,
  toTranslate: keyof typeof english | keyof typeof macedonian
) => {
  return language === 'macedonian'
    ? macedonian[toTranslate]
    : english[toTranslate]
}
