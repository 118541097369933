import React from 'react'
import { translation } from 'assets/translations/translations'
import english from 'assets/translations/en.json'
import macedonian from 'assets/translations/mk.json'
import './Product.css'

type Props = {
  img: string
  text: keyof typeof english | keyof typeof macedonian
  header: keyof typeof english | keyof typeof macedonian
  before: boolean
  language: string
}

const Product = ({ img, text, header, before, language }: Props) => (
  <div className='product-main'>
    {before && (
      <div className='product-text'>
        <div className='product-header'>{translation(language, header)}</div>
        <div className='product-about'>{translation(language, text)}</div>
      </div>
    )}
    <div
      className={`product-img-container ${
        before && 'product-img-container-after'
      }`}
    >
      <img src={img} alt='Electronics' className='product-img' />
    </div>
    {!before && (
      <div className='product-text'>
        <div className='product-header product-after'>
          {translation(language, header)}
        </div>
        <div className='product-about product-after'>
          {translation(language, text)}
        </div>
      </div>
    )}
  </div>
)

export default Product
