import React from 'react'
import './Attributes.css'
import { translation } from 'assets/translations/translations'

type Props = {
  language: string
}

const Attributes = ({ language }: Props) => {
  return (
    <div className='attributes-main'>
      <div className='attribute'>
        <span className='attribute-icon'>
          <i className='fas fa-check-circle' />
        </span>
        {translation(language, 'qualitySolutions')}
      </div>
      <div className='attribute'>
        <span className='attribute-icon'>
          <i className='fas fa-sliders-h' />
        </span>
        {translation(language, 'fullyCustomizable')}
      </div>
      <div className='attribute'>
        <span className='attribute-icon'>
          <i className='fas fa-lightbulb' />
        </span>
        {translation(language, 'innovativeDesign')}
      </div>
    </div>
  )
}

export default Attributes
