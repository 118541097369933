import React from 'react'
import GoogleMap from 'google-maps-react-markers'
import { translation } from 'assets/translations/translations'
import './Footer.css'

type Props = {
  language: string
}

const Footer = ({ language }: Props) => {
  return (
    <div className='footer-main'>
      <div className='map'>
        <div style={{ height: '300px', width: '300px' }} id='inner-map'>
          <GoogleMap
            apiKey='AIzaSyDUXU7k_li8p4vWnNtKo_S2hVz7ss62IlQ'
            defaultCenter={{ lat: 41.995505, lng: 21.404493 }}
            defaultZoom={18}
            mapMinHeight='200px'
          >
            <Marker lat={41.995505} lng={21.404493} />
          </GoogleMap>
        </div>
      </div>
      <div className='contact-us'>
        <div>
          {translation(language, 'contactUs')}
          <form
            action='mailto:ile@ile-soft.com'
            method='post'
            style={{ marginTop: '10px' }}
          >
            <button type='submit' className='email-btn'>
              {translation(language, 'emailBtn')}
            </button>
          </form>
        </div>
        <div className='working-hours'>
          <span>{translation(language, 'open')}</span>
          <span>{translation(language, 'openOn')}</span>
          <span style={{ marginTop: '15px' }}>
            {translation(language, 'phone')}
            {translation(language, 'phoneNr')}
          </span>
        </div>
      </div>
    </div>
  )
}
// eslint-disable-next-line
const Marker = (_props: any) => {
  return (
    <div>
      <div className='marker'></div>
      <span className='beacon'></span>
    </div>
  )
}

export default Footer
