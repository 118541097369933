import React from 'react'
import Logo from 'assets/logo/Logo.svg'
import './Header.css'
import { translation } from 'assets/translations/translations'

type Props = {
  language: string
  setLanguage: (language: string) => void
}

const Header = ({ language, setLanguage }: Props) => {
  return (
    <div className='header-main'>
      <div className='logo-container'>
        <img src={Logo} className='logo' />
        <span className='logo-name'>{translation(language, 'title')}</span>
      </div>
      <div className='icons'>
        <a
          href='https://www.facebook.com/ilesoft'
          target='_blank'
          rel='noreferrer'
          className='icon-link'
        >
          <i className='fa-brands fa-facebook-f icon' />
        </a>
        <a
          href='https://www.instagram.com/ilesoftelectronics'
          target='_blank'
          rel='noreferrer'
          className='icon-link'
        >
          <i className='fa-brands fa-instagram icon' />
        </a>
      </div>
      <div className='translate'>
        <div
          className='translate-btn'
          style={{
            border:
              language === 'macedonian'
                ? '1px solid var(--main-brand-color)'
                : '0px',
          }}
          onClick={() => setLanguage('macedonian')}
        >
          MK
        </div>
        <div
          onClick={() => setLanguage('english')}
          style={{
            border:
              language === 'english'
                ? '1px solid var(--main-brand-color)'
                : '0px',
          }}
          className='translate-btn'
        >
          EN
        </div>
      </div>
    </div>
  )
}

export default Header
