import React from 'react'
import { translation } from 'assets/translations/translations'
import './MainBanner.css'

type Props = {
  language: string
}

const MainBanner = ({ language }: Props) => (
  <div className='main-banner-main'>
    <div className='main-banner-text'>
      {translation(language, 'headerText')}
    </div>
  </div>
)

export default MainBanner
