import React, { useState } from 'react'
import Header from 'components/Header/Header'
import MainBanner from 'components/MainBanner/MainBanner'
import AboutUs from 'components/AboutUs/AboutUs'
import Attributes from 'components/Attributes/Attributes'
import Product from 'components/Product/Product'
import Electronics from 'assets/images/electronics1.jpg'
import ExchangeRate from 'assets/images/kursnalista1.jpg'
import LEDClock from 'assets/images/ledclock1.jpg'
import MovingMessage from 'assets/images/movingmessage1.jpg'
import Sport from 'assets/images/sport.jpg'
import Totem from 'assets/images/totem1.jpg'
import english from 'assets/translations/en.json'
import macedonian from 'assets/translations/mk.json'
import Footer from 'components/Footer/Footer'
import './App.css'
import { translation } from 'assets/translations/translations'

const products = [
  {
    img: Electronics,
    text: 'electronicsSub',
    header: 'electronics',
    before: true,
  },
  {
    img: ExchangeRate,
    text: 'exchangeRateSub',
    header: 'exchangeRate',
    before: false,
  },
  {
    img: LEDClock,
    text: 'ledClockSub',
    header: 'ledClock',
    before: true,
  },
  {
    img: MovingMessage,
    text: 'movingMessageDisplaysSub',
    header: 'movingMessageDisplays',
    before: false,
  },
  {
    img: Sport,
    text: 'sportTrafficSub',
    header: 'sportTraffic',
    before: true,
  },
  {
    img: Totem,
    text: 'gasStationDisplaySub',
    header: 'gasStationDisplay',
    before: false,
  },
]

function App() {
  const [language, setLanguage] = useState('macedonian')
  return (
    <div className='main-class'>
      <Header setLanguage={setLanguage} language={language} />
      <MainBanner language={language} />
      <Attributes language={language} />
      <AboutUs language={language} />
      {products.map((product, index) => (
        <Product
          key={index}
          img={product.img}
          text={product.text as keyof typeof english | keyof typeof macedonian}
          header={
            product.header as keyof typeof english | keyof typeof macedonian
          }
          before={product.before}
          language={language}
        />
      ))}
      <Footer language={language} />
      <div className='copyright'>
        <span>{translation(language, 'copyright')}</span>
        <span>{translation(language, 'design')}</span>
      </div>
    </div>
  )
}

export default App
